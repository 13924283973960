import topLogo from "../../assets/top_logo.svg";
import './header.css';
import {ReactComponent as BurgerMenu} from "../../assets/burger_menu.svg";
import {Offcanvas} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import {useHref, useLocation} from "react-router-dom";

const HeaderMenuList = ({sendToParent}) => {
    return (
        <ul onClick={sendToParent}>
            <li><a href="/#about_section">Despre companie</a></li>
            <li><a href="/#servicii_section">Servicii</a></li>
            {/*<li><a href="/#pay_online_section">Digitalizarea serviciilor</a></li>*/}
            {/*<li><a href="/#utils_info_section">Informații utile</a></li>*/}
            {/*<li><a href="/#questions_section">Întrebări frecvente</a></li>*/}
            <li><a href="/#footer_section">Contacte</a></li>
        </ul>
    )
}

const Header = () => {
    const [show, setShow] = useState(false);
    const [target, setTarget] =useState('')
    const canv = useRef();
    let location = useLocation();

    const handleClose = (e) => {
        setShow(false);
    }
    const handleShow = () => setShow(true);
    const sendToParent = (e) => {
        if(location.pathname === '/') {
            e.preventDefault();
            e.stopPropagation();
            if(e.target.hash.length) {
                setTarget(e.target.hash);
                setShow(false);
            }
        }

    }
    useEffect(() => {
        if(!show && target.length) {
            setTimeout(()=>{
                const el = document.getElementById(target.replace('#',''))
                console.log(window.scrollY, el)
                const top = window.scrollY + el.getBoundingClientRect().top - 120;
                window.scrollTo({ top, behavior: "smooth" })
            },500)

        }
    }, [show]);
    return (
        <header className="header-wrap header-1 header-5 custom-header-menu">
            <div className="container d-flex justify-content-between align-items-center p-0">
                <div className="logo">
                    <a href="/">
                        <img src={topLogo} alt="logo" />
                    </a>
                </div>
                <div className="header-right-area d-flex">
                    <div className="main-menu d-none d-lg-block">
                        <nav id="mobile-menu-3">
                            <HeaderMenuList />
                        </nav>
                    </div>
                    <div className="custom-mobile-menu">
                        <button className="btn btn-primary mobile-menu-burger d-lg-none" type="button" onClick={handleShow}>
                            <BurgerMenu />
                        </button>

                        <Offcanvas className='custom-offcanvas-menu' placement={'end'} ref={canv} show={show}  scroll={true} restoreFocus={true} autoFocus={false} enforceFocus={false}>
                            <Offcanvas.Header closeButton onHide={handleClose}>
                                <div className="logo">
                                    <a href="/">
                                        <img src={topLogo} alt="logo" />
                                    </a>
                                </div>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <nav id="mobile-menu-4">
                                    <HeaderMenuList sendToParent={sendToParent} />
                                </nav>
                            </Offcanvas.Body>
                        </Offcanvas>

                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
