import React from "react";
import Image from 'react-bootstrap/Image';
import bottomLogo from "../../assets/bottom_logo.svg";
import './footer.css';
import Slider from "react-slick";
import Abs from "../../assets/images/logo-parteneri/abs_logo.png";
import ApaCanal from "../../assets/images/logo-parteneri/apa_canal.png";
import BasLift from "../../assets/images/logo-parteneri/baslift.png";
import Dglca from "../../assets/images/logo-parteneri/dglca.png";
import BasconsLux from "../../assets/images/logo-parteneri/basconslux.png";
import LiftService from "../../assets/images/logo-parteneri/liftservice.png";
import MinisterulApararii from "../../assets/images/logo-parteneri/ministerul_apararii.png";
import OrasulProtejat from "../../assets/images/logo-parteneri/orasul_protejat.png";
import Viaduct from "../../assets/images/logo-parteneri/viaduct.jpeg";
import Money from "../../assets/images/logo-parteneri/money.png";
import VictoriaBank from "../../assets/images/logo-parteneri/victoriabank.svg";
import PostaMoldovei from "../../assets/images/logo-parteneri/posta_moldovei.svg";
import Bpay from "../../assets/images/logo-parteneri/bpay.svg";
import Maib from "../../assets/images/logo-parteneri/maib.svg";
import Lift from "../../assets/images/logo-parteneri/lift.svg";
import Micb from "../../assets/images/logo-parteneri/micb.svg";
import Paynet from "../../assets/images/logo-parteneri/paynet.svg";
import RunPay from "../../assets/images/logo-parteneri/runpay.svg";
import Varincom from "../../assets/images/logo-parteneri/varincom.svg";
import FooterBgImages from "../../assets/images/slider-img/image_21x9.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SwipeToSlide = () => {
    const settings = {
        className: "center",
        infinite: true,
        slidesToShow: 3,
        swipeToSlide: true,
        centerPadding: "60px",
        // slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 1,
        cssEase: "linear",
        pauseOnHover: true,
        arrows: false,
        centerMode: true,
        touchThreshold: 0,
        draggable: true,
        responsive: [
            {
                breakpoint: 1199.98,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991.98,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767.98,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 574.98,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
    return (
        <div className="slider-container partener-logo-slider">
            <Slider {...settings}>
                <div>
                    <a href="https://www.victoriabank.md/" target="_blank">
                        <Image src={VictoriaBank} />
                    </a>
                </div>
                <div>
                    <a href="https://www.posta.md/ro" target="_blank">
                        <Image src={PostaMoldovei} />
                    </a>
                </div>
                <div>
                    <a href="https://abs.md/" target="_blank">
                        <Image src={Abs} />
                    </a>
                </div>
                <div>
                    <a href="https://www.acc.md/" target="_blank">
                        <Image src={ApaCanal} />
                    </a>
                </div>
                <div>
                    <a href="https://baslift.md/" target="_blank">
                        <Image src={BasLift} />
                    </a>
                </div>
                <div>
                    <a href="https://www.bpay.md/ro" target="_blank">
                        <Image src={Bpay} />
                    </a>
                </div>
                <div>
                    <a href="https://dglca.md/" target="_blank">
                        <Image src={Dglca} />
                    </a>
                </div>
                <div>
                    <a href="http://lift.md/en/homepage/" target="_blank">
                        <Image src={Lift} />
                    </a>
                </div>
                <div>
                    <a href="https://liftservice.md/" target="_blank">
                        <Image src={LiftService} />
                    </a>
                </div>
                <div>
                    <a href="https://basconslux.md/" target="_blank">
                        <Image src={BasconsLux} />
                    </a>
                </div>
                <div>
                    <a href="https://www.maib.md/ro" target="_blank">
                        <Image src={Maib} />
                    </a>
                </div>
                <div>
                    <a href="https://micb.md/" target="_blank">
                        <Image src={Micb} />
                    </a>
                </div>
                <div>
                    <a href="https://www.army.md/" target="_blank">
                        <Image src={MinisterulApararii} />
                    </a>
                </div>
                <div>
                    <a href="https://orasulprotejat.md/" target="_blank">
                        <Image src={OrasulProtejat} />
                    </a>
                </div>
                <div>
                    <a href="https://paynet.md/ro" target="_blank">
                        <Image src={Paynet} />
                    </a>
                </div>
                <div>
                    <a href="https://runpay.md/ro/" target="_blank">
                        <Image src={RunPay} />
                    </a>
                </div>
                <div>
                    <a href="https://varincom.md/" target="_blank">
                        <Image src={Varincom} />
                    </a>
                </div>
                <div>
                    <a href="https://www.facebook.com/eldoradoterra/?locale=ro_RO" target="_blank">
                        <Image src={Viaduct} />
                    </a>
                </div>
            </Slider>
        </div>
    );
}

const Footer = () => {
    return (
        <footer id="footer_section" className="footer-1 footer-wrap">
            <div className="footer-widgets-wrapper">
                <div className="container">
                    <div className="row d-flex justify-content-between mt-4">
                        <div className="col-xl-3 pe-xl-0 col-md-6">
                            <div className="single-footer-wid site_info_widget">
                                <div className="wid-title">
                                    <h3>Contacte</h3>
                                </div>
                                <div className="contact-us">
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-phone"></i>
                                        </div>
                                        <div className="contact-info d-flex flex-column">
                                            <a href="tel:+37362007800">+373 62-00-78-00</a>
                                            <a href="tel:+37362007800">+373 22-80-33-44</a>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-envelope"></i>
                                        </div>
                                        <div className="contact-info">
                                            <a href="mailto:contacte@profadmin.md">contacte@profadmin.md</a>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-map-marker-alt"></i>
                                        </div>
                                        <div className="contact-info">
                                            <p>Bd. Renașterii nr.6, Chișinău</p>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-clock"></i>
                                        </div>
                                        <div className="contact-info">
                                            <p>Luni-Vineri, 09:00-16:00</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 footer-bg-images">
                            <Image src={FooterBgImages} />
                            {/* <div className="single-footer-wid">
                                <div className="wid-title">
                                    <h3>Quick Links</h3>
                                </div>
                                <ul>
                                    <li><a href="about.html">Despre noi</a></li>
                                    <li><a href="services.html">Servicii</a></li>
                                    <li><a href="services.html">Facturare</a></li>
                                    <li><a href="services.html">Info utile</a></li>
                                    <li><a href="services.html">Intrebari frecvente</a></li>
                                </ul>
                            </div> */}
                        </div>

                        {/* <div className="col-sm-6 col-xl-4 offset-xl-1">
                            <div className="single-footer-wid newsletter_widget">
                                <div className="wid-title">
                                    <h3>Newsletter</h3>
                                </div>
                                <div className="newsletter_box">
                                    <form action="#">
                                        <Envelope />
                                        <input type="email" placeholder="Enter email address" required />
                                        <button className="submit-btn" type="submit">Aboneaza-te acum</button>
                                    </form>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="footer-cta-wrapper">
                <div className="container">
                    <div className="footer-cta-bg-wrapper">
                        <div className="row justify-content-around align-items-center">
                            <div className="col-md-5 col-12">
                                <div className="footer-logo">
                                    <a href="/">
                                        <img src={bottomLogo} alt="logo" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="footer-middle-text text-white">
                                    <SwipeToSlide />
                                </div>
                            </div>
                            {/* <div className="col-md-4 col-12">
                                <div className="footer-social-icon">
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                    <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12 text-center text-md-start">
                            <div className="copyright-info">
                                <p>© 2024 Copyright by ProfAdmin. Toate drepturile rezervate <q>Profadmin SRL</q>.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="footer-menu text-center text-md-end">
                                <ul className="d-flex justify-content-end align-items-center">
                                    <li className="money-style">
                                        <Image src={Money} />
                                    </li>
                                    <li className="d-flex align-items-center">
                                        {/*<a href="/policy" target="_blank">Politica de Confidențialitate</a>*/}
                                    </li>
                                    {/* <li><a href="#">Contact</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
